import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
} from '@chakra-ui/react'
import ClosedProjects from './components/closedProjects'
import OngoingProjects from './components/ongoingProjects'

export default function ProjectsPage () {
  return (
    <>
      <Text as='b' ml='20px' fontSize='3xl'>Projects</Text>
      <Box pos='relative' mt='20px' >
        <Tabs>
          <TabList ml='20px' mr='20px' >
            <Tab
              border='2px solid'
              borderColor='transparent'
              borderTopRadius='lg'
              borderBottom='none'
              bg='#a6a3ff'
              color='white'
              _selected={{ bg: '#6b52ff', color: 'white' }}
              mr='5px'
            >
              Ongoing projects
            </Tab>
            <Tab
              border='2px solid'
              borderColor='transparent'
              borderTopRadius='lg'
              borderBottom='none'
              bg='#a6a3ff'
              color='white'
              _selected={{ bg: '#6b52ff', color: 'white' }}
            >
              Closed projects
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
            <OngoingProjects />
            </TabPanel>
            <TabPanel>
              <ClosedProjects />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  )
}
