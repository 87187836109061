import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner
} from '@chakra-ui/react'
import { useGetClosedProjects } from 'api/projects-api'
import ProjectTable from '../../../components/ProjectTable'
import {
  columnsDataClosedProjects
} from '../variables/columnsData'
import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { TiDeleteOutline } from 'react-icons/ti'

export default function ClosedProjects () {
  const [searchField, setSearchField] = useState('')

  const handleSearchClick = async (e: any) => {
    e && e.stopPropagation()
    e && e.preventDefault()
    await reloadProjects(searchField)
  }

  const handleResetInput = async () => {
    setSearchField('')
    await reloadProjects()
  }

  const reloadProjects = async (search?: string) => {
    try {
      await getProjects({
        params: {
          search
        }
      })
    } catch (error) {
      console.error('Error during reload:', error)
    }
  }

  const [
    {
      data: projectsData,
      loading: getProjectsInProgress,
      error: getProjectsError
    },
    getProjects
  ] = useGetClosedProjects()

  return (
    <>
      {/* loading data */}
      {getProjectsInProgress && <Spinner />}

      {/* error during loading data */}
      {getProjectsError && <p>Error fetching data</p>}

     

      {/* get result */}
      {
      !getProjectsInProgress &&
      projectsData &&
      projectsData.length > 0 &&
      <>
        <Flex>
          <Flex direction='column' mb={4} mr='10px'>
            <InputGroup>
              {searchField && <InputLeftElement>
                <Button size='20' onClick={async () => await handleResetInput()}>
                  <TiDeleteOutline size='20' />
                </Button>
              </InputLeftElement>}
              <Input
                variant='tableSearch'
                placeholder='Search by project name'
                onChange={(e) => setSearchField(e.target.value)}
                value={searchField}
              />
            </InputGroup>
          </Flex>
          <Button onClick={async (e) => await handleSearchClick(e)} bg='white' borderRadius='20px'>
            <FaSearch color='black' />
          </Button>
        </Flex>
        <ProjectTable
          columnsData={columnsDataClosedProjects}
          tableData={projectsData}
          total={projectsData.length}
          reloadData={getProjects}
        />
      </>
      }

      {/* no data */}
      {
      !getProjectsInProgress &&
      projectsData &&
      projectsData.length === 0 &&
        <p>No data</p>
    }
    </>
  )
}
