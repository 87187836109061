
export const SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL || 'https://api.thephoton.hu';

export default {
  environment: process.env.REACT_APP_ENV || 'production',
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.thephoton.hu/api',
  SOCKET_URL,
  reCaptchaKey: process.env.REACT_APP_CAPTCHA_KEY || '6Ld9kb4eAAAAAPz2lWRJTnas3ORa3mKSCut7e38E',
  appBaseUrl: process.env.REACT_APP_BASE_URL || 'https://photon.rocks'
}
