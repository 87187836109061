import { useCallback, useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { TiDeleteOutline } from 'react-icons/ti'
import io from 'socket.io-client'

import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useToast
} from '@chakra-ui/react'

import { useGetOngoingProjects } from 'api/projects-api'
import ProjectTable from '../../../components/ProjectTable'
import { columnsDataOngoingProjects } from '../variables/columnsData'
import { getItem, USER } from '../../../hooks/useLocalStorage'
import {SOCKET_URL} from '../../../config';


export default function OngoingProjects () {
  const toast = useToast()
  const [searchField, setSearchField] = useState('')
  const [socket, setSocket] = useState(null)
  const [
    {
      data: projectsData,
      loading: getProjectsInProgress,
      error: getProjectsError
    },
    getProjects
  ] = useGetOngoingProjects()

  const reloadProjects = useCallback(
    async () => {
      try {
        await getProjects({
          params: {
            search: searchField || undefined
          }
        })
      } catch (error) {
        console.error('Error during reload:', error)
      }
    },
    [getProjects, searchField]
  )

  useEffect(
    () => {
      socket?.off('projectAvailable')

      socket?.on(
        'projectAvailable',
        () => reloadProjects()
      )
    },
    [socket]
  )

  useEffect(
    () => {
      const userCode = getItem(USER)
      const user = userCode ? JSON.parse(userCode) : null

      const socket = io(
        SOCKET_URL,
        { auth: { token: user?.accessToken } }
      )

      setSocket(socket)

      return () => {
        socket?.disconnect()
        setSocket(null)
      }
    },
    []
  )

  const handleSearchClick = async (e: any) => {
    e && e.stopPropagation()
    e && e.preventDefault()
    await reloadProjects()
  }

  const handleResetInput = async () => {
    setSearchField('')
    await reloadProjects()
  }

  useEffect(() => {
    if (getProjectsError) {
      console.error(getProjectsError)
      toast({
        title: 'Server error',
        description: 'Error while fetching data',
        status: 'error',
        isClosable: true
      })
    }
  }, [getProjectsError])

  return (
    <>
      {/* loading data */}
      {getProjectsInProgress && <Spinner />}

      {/* error during loading data */}
      {getProjectsError && <p>Error fetching data</p>}

      {/* get result */}
      {
      !getProjectsInProgress &&
      projectsData &&
      projectsData.length > 0 &&
      <>
        <Flex>
          <Flex direction='column' mb={4} mr='10px'>
            <InputGroup>
              {searchField && <InputLeftElement>
                <Button size='20' onClick={async () => await handleResetInput()}>
                  <TiDeleteOutline size='20' />
                </Button>
              </InputLeftElement>}
              <Input
                variant='tableSearch'
                placeholder='Search by project name'
                onChange={(e) => setSearchField(e.target.value)}
                value={searchField}
              />
            </InputGroup>
          </Flex>
          <Button onClick={async (e) => await handleSearchClick(e)} bg='white' borderRadius='20px'>
            <FaSearch color='black' />
          </Button>
        </Flex>
        <ProjectTable
          columnsData={columnsDataOngoingProjects}
          tableData={projectsData}
          total={projectsData.length}
          reloadData={getProjects}
        />
      </>
      }

      {/* no data */}
      {
      !getProjectsInProgress &&
      projectsData &&
      projectsData.length === 0 &&
        <p>No data</p>
    }
    </>
  )
}
