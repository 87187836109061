import {
  Avatar,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue
} from '@chakra-ui/react'

import { SearchBar } from 'components/navbar/searchBar/SearchBar'
import { SidebarResponsive } from 'components/sidebar/Sidebar'
import PropTypes from 'prop-types'
import React from 'react'

import { FaEthereum } from 'react-icons/fa'
import routes from 'routes'
import { useAuth } from 'hooks/useAuth'
import {
  NavLink,
  useNavigate
} from 'react-router-dom'

export default function HeaderLinks (props: { secondary: boolean }) {
  const { secondary } = props

  const {
    logout,
    user
  } = useAuth()
  const navigate = useNavigate()

  const navbarIcon = useColorModeValue('gray.400', 'white')
  const menuBg = useColorModeValue('white', 'navy.800')
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const ethColor = useColorModeValue('gray.700', 'white')
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)')
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900')
  const ethBox = useColorModeValue('white', 'navy.800')
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  )

  return (
    <Flex
      w={{ sm: '100px', md: 'auto' }}
      position='absolute'
      right='5'
      top='5'
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p='10px'
      borderRadius='30px'
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />
      {
      !!user &&
        <Menu>
          <MenuButton p='0px'>
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color='white'
              name={user.name}
              bg='#11047A'
              size='sm'
              w='40px'
              h='40px'
            />
          </MenuButton>
          <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={'#6b52ff'} border='none' color='black'>
            <Flex w='100%' mb='0px'>
              <Text
                ps='20px'
                pt='16px'
                pb='10px'
                w='100%'
                borderBottom='1px solid'
                borderColor={borderColor}
                fontSize='sm'
                fontWeight='700'
                color={'white'}
                
              >
                👋&nbsp; Hello there!
              </Text>
            </Flex>
            <Flex flexDirection='column' p='10px' >
              <MenuItem bg='#6b52ff'>
                <Link
                  as={NavLink}
                  to='/intel/profile-settings'
                  mt={{
                    base: '0px',
                    md: '10px',
                    lg: '0px',
                    xl: '10px',
                    '2xl': '0px'
                  }}
                >
                  <Text
                    fontWeight='semibold'
                    bg='#6b52ff'
                    color='white'
                    borderRadius='8px'
                    p='14px'
                    fontSize='sm'
                  >
                    Profile settings
                  </Text>
                </Link>
              </MenuItem>
              <MenuItem bg='#6b52ff'>
                <Text
                  fontWeight='semibold'
                  borderRadius='8px'
                  p='14px'
                  bg='#6b52ff'
                  color='white'
                  _hover={{ color: 'gray.200' }}
                  onClick={
                      (e) => {
                        e?.preventDefault()
                        e?.stopPropagation()

                        logout()
                        navigate('/login')
                      }
                    }
                  fontSize='sm'
                >
                  Log out
                </Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
    }
    </Flex>
  )
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
}
